.div{

}

.title{

    border-bottom: solid 2px grey;
    margin-bottom: 50px;
    color: #fff;
}

.list{
    margin-bottom: 32%;
}
.list ul{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
}
.list ul:first-child{

}
.list li{
    list-style: none;
    text-align: center;

    padding: 10px 0;
}
.list li button{
 min-width: 200px;
}
.list li:last-child,.list li:nth-child(3){
    border: none;
}