.chartBlock{
  padding: 10px 0px;

}
.charRow{
  overflow: hidden;
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: 14% 14% 14% 14% 14% 14% 14% 14% ;
  border-bottom: solid 1px #a8a8a8;
  &:last-child{
    border: none;
  }
  P{
    text-align: center;
    font-size: 12px;
    color: #a8a8a8;
    margin: 5px 0;
    &:first-child{
      text-align: start;
    }
    &:nth-child(2){
      padding-right: 10px;
    }
    &:nth-child(3){
      padding-left: 2px;
    }
    &:nth-child(4){
      padding-left: 13px;
    }
    &:nth-child(5){
      padding-left: 25px;
    }
    &:nth-child(6){
      padding-left: 40px;
    }
  }
  &.active{
    p{
      color: #000;
      font-weight: bold;
    }
  }
}
.gray{
  background: #a8a8a8;
  p{
    margin: 5px 0;
    color: #000;
  }
}

.spinner_block1{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 700px;
}
.title{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  img {
    right: 0;
    top: 5px;
    max-width: 60px;
    width: 100%;
    justify-self: center;
  }
  div{
    position: absolute;
    right: 0;
    top: 0;
    display: grid;
    align-items: center;
    justify-content: center;
    span{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    i{
      font-style: normal;
    }
  }
  h2{
    font-size: 24px;
    line-height: 1;
    span{
      color: goldenrod;
      font-size: 18px;
    }
  }
  .date{
    font-size: 18px;
  }
}