.div{

}
.title{
    border-bottom: solid 2px grey;
    margin-bottom: 50px;

}

.list{
    margin-bottom: 32%;
    border: solid 1px grey;
    display: grid;
    grid-template-columns: 130px 1fr;
    justify-content: space-between;

}
.list ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
.list ul:first-child{
    border-right: solid 1px grey;
}
.list li{
    list-style: none;
    text-align: center;
    border-bottom: solid 1px grey;
    padding: 10px 0;
}
.list li:last-child{
  border: none;
}