.nav_bar{

}
.list{
    padding-left: 0;
    list-style: none;
    display: flex;
    grid-gap: 0px;
    margin:  15px 0px 0;
    border-bottom: solid 1px grey;
}
.line{
    border-bottom: solid 1px grey;
}
.item{
    padding: 10px 20px;
    border: solid 1px #666666;
    border-top-left-radius: 5px ;
    border-top-right-radius: 5px ;
    transition: .3s;
    cursor: pointer;
}
.item:hover{
    transition: .3s;
    border: solid 1px #fff;
}
.item.active{
    color: #000;
    padding: 10px 20px;
    background: #fff;
    border: solid 1px #fff;
    border-top-left-radius: 5px ;
    border-top-right-radius: 5px ;

}