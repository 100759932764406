.title {
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff !important;
  border-bottom: solid 1px rgba(49, 49, 49, 0.631372549);
  padding: 10px 0;
  border-top:solid 1px rgba(49, 49, 49, 0.631372549);

}