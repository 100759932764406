.spinner_block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 700px;
}

.content {

  display: grid;
  grid-template-columns:  calc(55% - 10px) calc(45% - 10px);
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
}

.chart {
  max-width: 100%;
}

.table {
  width: 100%;

  .header, .row, .footer {
    background: #a8a8a8;
    padding: 7px 0;
    display: grid;
    grid-template-columns: 10% 10% 30% 15% 15% 20%;

    div {
      text-align: center;
      font-size: 12px;
    }
  }

  .row {
    padding: 0;
    background: inherit;
    border-bottom: solid 1px #a8a8a8;
    div:last-child{
      overflow: hidden;
      background: #fff;
      p{
        padding: 0;
        margin: 0;
        z-index: 11;
        color: #000;
      }
      .green {
        display: grid;
        width: 100%;
        position: relative;
        i{
          right: 0;
          position: absolute;
          height: 100%;
          width: 100%;
        }
      }

      .red {
        display: grid;
        width: 100%;
        position: relative;
        i{
          right: 0;
          position: absolute;
          height: 100%;
          width: 40%;

        }
      }
    }
  }

  .footer {

  }
}

.green {
  color: green;

}

.red {
  color: red;

}

.title{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  img {
    right: 0;
    top: 5px;
    max-width: 60px;
    width: 100%;
    justify-self: center;
  }
  div{
    position: absolute;
    right: 0;
    top: 0;
    display: grid;
    align-items: center;
    justify-content: center;
    i{
      font-style: normal;
    }
  }
  h2{
    font-size: 24px;
    line-height: 1;
    span{
      color: goldenrod;
      font-size: 18px;
    }
  }
  .date{
    font-size: 18px;
  }
}


.rate {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;

  .green {
    position: relative;
    height: 18px;
    i{
      position: absolute;

      height: 18px;
      background: rgba(29, 129, 162, 0.71);
      top: 0;
      left: 0;
    }

  }

  .red {
    position: relative;
    height: 18px;
    i{
      position: absolute;

      height: 18px;
      background: red;
      top: 0;
      right: 0;
    }
  }
}