.chartLine{
    margin-top: 0px;
    background: #fff;
}
.spinner_block{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 700px;
}


.title{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;
    img {
        right: 0;
        top: 5px;
        max-width: 60px;
        width: 100%;
        justify-self: center;
    }
    div{
        position: absolute;
        right: 0;
        top: 0;
        display: grid;
        align-items: center;
        justify-content: center;
        span{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        i{
            font-style: normal;
        }
    }
    h2{
        font-size: 24px;
        line-height: 1;
        span{
            color: goldenrod;
            font-size: 18px;
        }
    }
    .date{
        font-size: 18px;
    }
}