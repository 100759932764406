.container{
    margin: 20px auto 0;
    background: #f3f3f3;
    max-width: 1440px;
    border-radius: 5px;
    box-shadow: 2px 1px 4px 1px #313131a1;
    padding: 0px;

    .title{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 15px;
        img {
            right: 0;
            top: 5px;
            max-width: 60px;
            width: 100%;
            justify-self: center;
        }
        div{
            position: absolute;
            right: 0;
            top: 0;
            display: grid;
            align-items: center;
            justify-content: center;
            i{
                font-style: normal;
            }
        }
        h2{
            font-size: 24px;
            line-height: 1;
            span{
                color: goldenrod;
                font-size: 18px;
            }
        }
        .date{
            font-size: 18px;
        }
    }
}
.padding{
    padding: 15px;
    &:nth-child(odd){
        background: #fff;
    }
    &:nth-child(even){
        background: #f3f3f3;
    }
}
