.table{
    margin-top: 10px;
    display: grid;

}
.table_head{
    padding: 10px 0;
    display: grid;
    grid-template-columns: 30px 260px 130px repeat(12 , 1fr) 70px;
}
.head_item,
.footer_item{
    align-self: center;
    text-align: center;
}
.head_item{
    cursor:pointer;
}
.head_item:hover{
    text-decoration: underline;
}

.table_body{

}
.table_body_wrapper,
.table_footer_wrapper{
    display: grid;
    grid-template-columns: 260px 130px  repeat(12 , 1fr) 70px;
    align-items: center;
}
.table_body_wrapper{
    background: #fff;
}

.body_item{
    color: gray;
    padding: 10px 0;
    align-self: center;
    text-align: center;
    border-right: solid 1px grey;
    border-bottom: solid 1px grey;
}
.body_item:first-child,.body_item:nth-child(2){
 font-weight: 600;
    color: #000;
    font-size: 12px;
    padding: 13px 0;
}
.body_item:nth-child(2){
    font-size: 12px;
}

.body_item:last-child{
    border-right: none;
    background:rgb(57 57 57) ;
    color: #fff;
    border-bottom: unset;
    border-bottom: solid 1px rgb(57 57 57);
    transform: scale(1.03 , 1);
}

.table_footer{
    order: -1;
}
.table_footer_wrapper{

}
.footer_item{
    padding: 10px 0;

}