.table{

    width: 100%;
}

.table_block{

    border-collapse: collapse;
    color: #000;
    width: 100%;
}

.table_block th,
.table_block td{
    padding: 10px;
    text-align: center;
}

thead{
    color: #fff;
}
tbody{
    background: #fff;

}

.table_block tbody tr:nth-child(2n){
    background: #bfbfbf;
}
.table_block tbody tr:nth-child(2n) input{
    background: #bfbfbf;
}
.table_block tbody tr:nth-child(2n+1) input{
    background: none;
}

.table_block tbody td{
    border: solid 1px black;
    /*transition: .3s;*/
    font-weight: 500;
}

 .table_block tbody td:hover{
    background: #000000;
     color: #fff;
}

.table_block tbody tr{
    border: none;
}
.table_block tbody td input{
    max-width:50px;
    text-align:center;
    border:none;
    /*transition: .3s;*/
    font-size: 17px;
    font-weight: normal;
}
.table_block tbody td:hover input{
    background: #000000;
    color: #fff !important;

}

.table_block tbody td input:focus{
  outline: none;
}

.table_block tbody tr td:first-child{
    min-width: 200px;
}
.table_block tbody tr td:last-child,
.table_block tbody tr td:last-child input{
    background: rgba(8, 8, 8, 0.8);
    border: none;
    color: #fff !important;
    font-weight: bold;
}


.table_block tbody td input::-webkit-outer-spin-button,
.table_block tbody td input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.table_block tbody td input[type='number'] {
    -moz-appearance:textfield;
}