.table {
    margin-top: 10px;
    display: grid;
}

.table_head {
    padding: 10px 0;
    display: grid;
    grid-template-columns: 30px  130px 150px repeat(12, 1fr)  70px;
}


.head_item,
.footer_item {
    align-self: center;
    text-align: center;
}

.table_body {

}

.head_item {
    cursor: pointer;
}

.head_item:hover {
    text-decoration: underline;
}

.type-block:hover {
    text-decoration: unset;
}

.head_item.type-block {
    cursor: none;
}

.table_body_wrapper {
    display: grid;
    grid-template-columns:  130px 150px repeat(12, 1fr)  70px;
    /*display: flex;*/
    /*justify-content: space-between;*/
}


.table_footer_wrapper {
    display: grid;
    grid-template-columns:  30px  130px 150px repeat(12, 1fr)  70px;
}

.table_body_wrapper {
    background: #fff;
    border-bottom:solid 1px black;
}

.body_item {
    color: gray;
    text-align: center;
    border-left: 1px solid black;
    padding: 10px 0;
  }

/*.body_item:nth-child(3), .body_item:nth-child(2), .body_item:nth-child(1) {*/
/*    font-weight: 600;*/
/*    color: #000;*/
/*}*/
.body_item:nth-child(2), .body_item:nth-child(1) {
    font-weight: 600;
    color: #000;
}

.body_item:nth-child(2),
.body_item:nth-child(1) {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/*.table_body_wrapper_pc .body_item:nth-child(3),*/
/*.table_body_wrapper_pc .body_item:nth-child(2),*/
/*.table_body_wrapper_pc .body_item:nth-child(1) {*/
/*    font-size: 12px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    color: #000;*/
/*    font-weight: 600;*/
/*}*/
/*.table_body_wrapper_pc .body_item:nth-child(1){*/
/*    font-size: 9px;*/
/*    font-weight: bolder;*/
/*}*/



.body_item:last-child {
    background: rgb(57 57 57);
    color: #fff;
}

.table_footer {
    order: -1;
}

.table_footer_wrapper {

}

.footer_item {
    padding: 10px 0;

}

#type_block_model:hover {
    text-decoration: unset;
}

#type_block_model {
    cursor: unset;
}

.table_head_pc{
    grid-template-columns: 30px 90px 130px 150px repeat(12, 1fr)  70px;
}
.table_body_wrapper_pc{
    grid-template-columns: 90px 130px 150px repeat(12, 1fr)  70px;
}
.table_footer_wrapper_pc{
    grid-template-columns:  30px 90px 130px 150px   repeat(12, 1fr)  70px;
}