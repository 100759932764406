.block{

}
.block_2{
    border-bottom: solid 1px grey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}
.result{
    margin-top: 10px;
    display: grid;

    gap: 40px;
}
.result__item{
    border: solid 1px black;
}
.result__item .title{
    background: #ffffff;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.result__item .title h6{
    text-align: center;
    font-size: 18px;
    margin: 0;
    padding: 0;
    color: #000;
    text-transform: uppercase;
    font-weight: bold;
}

.result__item_head{
    background: #313131;
    width: 100%;
    height: 40px;
    display: grid;
    align-items: center;
    grid-template-columns: 40px 70px 3fr 60px 60px 60px ;
}

.result__item_body{
    background: #fff;
    width: 100%;
    height: 40px;
    display: grid;
    align-items: center;
    grid-template-columns: 40px 70px 3fr 60px 60px 60px ;
}
.result__item_body div,.result__item_head div{
   display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #000;
    border-right: solid 1px black;
}
.result__item_body div:last-child,.result__item_head div:last-child{
    border-right: none;
}
.result__item_head div{
    color: #fff;
}

.result__item .result__item_body{
    border-bottom: solid 1px black;
}
.result__item .result__item_body:last-child{
    border-bottom: none
}

.head_model{
    grid-template-columns: 30px 50px 3fr 4fr 50px 50px 50px ;
}

.body_model{
    grid-template-columns: 30px 50px 3fr 4fr 50px 50px 50px ;
}
.body_model div{
    font-size: 16px;
    text-align: center;
}